<template>
    <div class="intro">
      <vue-title></vue-title>
      <div class="hidden-overflow">
        <b-container id="styledElement">
            <div class="intro-container" id="resizeElement">
              <div class="avatars">
                <a class="avatar avatar1 "></a>
                <a class="avatar avatar2 "></a>
                <a class="avatar avatar3  "></a>
                <a class="avatar avatar4 "></a>
                <a class="avatar avatar5 "><router-link to="/avatar-game" class="avatar-message"></router-link></a>
                <a class="avatar avatar6 "></a>
              </div>
              <b-row>
                  <h1 :class="['hiddenload1', { 'animate__animated animate__backInDown': screenLoading }]">
                    <span class="desktop-title"><img src="../assets/img/avatar-title.png"></span>
                    <span class="mobile-title"><img src="../assets/img/avatar-title-mobile.png"></span>
                  </h1>
                  <div :class="['hiddenload1 text-holder', { 'animate__animated animate__backInDown animate__delay-1s': screenLoading }]" v-html="$t('intro.text')">
                  </div>
              </b-row>
              <b-row>
                  <b-col class="text-center">
                  <b-btn :class="['play-btn btn-zoom hiddenload1', { 'animate__animated animate__backInDown animate__delay-2s': screenLoading }]" to="/avatar-game">{{ $t('intro.btn_play') }}</b-btn>
                  <p class="have-account" :class="['play-btn btn-zoom hiddenload1', { 'animate__animated animate__backInDown animate__delay-3s': screenLoading }]">
                    คุณมีบัญชีแล้วหรือยัง <br />
                      <b-btn class="login-btn" to="/login">{{ $t('login.btn_text') }}</b-btn>
                  </p>
                  </b-col>
              </b-row>
            </div>
        </b-container>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Intro',
  data () {
    return {
      videoEnded: false,
      animations: ['animate__bounce', 'animate__tada', 'animate__wobble', 'animate__heartBeat', 'animate__jello', 'animate__heartBeat', 'animate__pulse', 'animate__flash', 'animate__swing']
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'loading']),
    screenLoading () {
      return !this.loading
    }
  },
  methods: {
    playAnimations () {
      const elements = document.querySelectorAll('.avatar')
      var animations = this.animations
      var methods = this
      elements.forEach(function (element) {
        var animation = animations[methods.random(animations.length)]
        element.classList.add('animate__animated', animation)
        element.addEventListener('animationend', () => {
          element.classList.remove('animate__animated', animation)
        })
      })
      setTimeout(function () { this.playAnimations() }.bind(this), 10000)
    },
    random: function (length) {
      return Math.floor(Math.random() * length)
    }
  },
  mounted () {
    const elements = document.querySelectorAll('.hiddenload1')
    elements.forEach(function (element) {
      element.addEventListener('animationstart', () => {
        element.classList.add('show')
      })
    })
    this.playAnimations()
  }
}
</script>

<style scoped>
    .have-account {
        color: #fff;
        margin-top:30px;
    }

    .login-btn {
        display: inline-block;
        background: url(../assets/img/choose-topic.png) center no-repeat !important;
        border: none;
        background-color: transparent !important;
        padding: 1px 10px;
        background-size: cover !important;
        border-radius: 0;
        font-size: 20px;
        color: #00AB4F !important;
        font-weight: 800;
        width: auto;
        text-decoration: none;
        height: 34px;
        text-transform: uppercase;
        letter-spacing: -0.5px;
        font-family: 'Open Sans', sans-serif;
        margin-top: 10px;
    }
</style>
